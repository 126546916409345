import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import commentName from './commentName';

import classNames from 'classnames';
import ConfirmModal from './ConfirmModal';
import Loading from './Loading';
import ScrollToTop from './ScrollToTop';
import { DataContext } from '../context/ContextProvider';

import { ReactComponent as NextLogo } from '../assets/icon/next.svg';

import './Review.scss';

const MISSING_SHOE_MODEL = 'Shoe model is required for the selected answer';
const MISSING_RANKED_ITEMS = 'Choose at least an answer';
const NEED_MORE_THAN_ONE_ITEM = 'Please select more than one shoe';
const NEED_FB_LOGGED_IN = 'You need to log in with Facebook';

// const QUESTION_NO = 1;
const OPEN_QUESTIONS = 3;
// const PLACE_HOLDERS = [
//   'name i.e. zoomx vaporfly next% 2',
//   'name i.e. metaspeed sky',
//   'name i.e. carbon x 2',
// ];

dayjs.extend(utc);
dayjs.extend(timezone);
const TIMEZONE = 'Asia/Bangkok';
const FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

function Review() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  // const [questionText, setQuestionText] = useState();
  const [items, setItems] = useState([]);
  const [error, setError] = useState(' ');
  const [ranking, setRanking] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const { data } = useContext(DataContext);
  const [landedAt] = useState(dayjs().tz(TIMEZONE).format(FORMAT));

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    axios.get('/api/products')
      .then((res) => {
        const { products } = res.data;
        prepareOpenQuestions(products);
      })
      .catch((err) => { 
        console.error("Review Error: " + err);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  // useEffect(() => {
  //   window.FB.getLoginStatus(response => {
  //     const { authResponse } = response;
  //     if (!authResponse) return;
  //     const { userID } = authResponse;
  //     if (!userID) return;
  //     dispatchFbContext({
  //       type: 'SET_USER_ID',
  //       payload: userID,
  //     });
  //   });
  // }, []);

  const prepareOpenQuestions = (products) => {
    for (let i = 0; i < OPEN_QUESTIONS; i++) {
      products.unshift({ open: true });
    }
    setItems(products);
  }

  const getRankOf = (itemId) => {
    for (let i = 0; i < ranking.length; i++) {
      if (ranking[i].itemId === itemId) return i;
    }
  };

  const onItemClick = (itemId) => {
    return (e) => {
      console.log('itemId', itemId);

      const found = ranking.find((item, i) => {
        return item.itemId === itemId;
      });
      console.log('found', found);

      if (found) return removeItem(itemId);

      const newRanking = [
        ...ranking,
        {
          ...items[itemId],
          itemId,
          selectedTime: dayjs().tz(TIMEZONE).format(FORMAT),
          landedAt,
        },
      ]
      setRanking(newRanking);
    };
  }

  const removeItem = (itemId) => {
    let rankItemIndex = findRankItemIndex(itemId)
    if (rankItemIndex === -1) return;

    const newRanking = [...ranking];
    newRanking.splice(rankItemIndex, 1);
    setRanking(newRanking);
  };

  const findRankItemIndex = (itemId) => {
    for (let i = 0; i < ranking.length; i++) {
      if (ranking[i].itemId === itemId) {
        return i;
      }
    }
    return -1;
  };

  const onNextClick = () => {
    setError();
    switch (ranking.length) {
      case 0:
        setError(MISSING_RANKED_ITEMS);
        return;
      case 1:
        setError(NEED_MORE_THAN_ONE_ITEM);
        return;
      default:
    }

    for (const item in ranking) {
      const { model } = ranking[item];
      // if (!brand || !model) {
      if (!model) {
        setError(MISSING_SHOE_MODEL);
        return;
      }
    }

    setShowModal(true);
  };

  const onInputChange = (itemId, field) => {
    return (e) => {
      // an item's value is being updated before ranked
      updateItemValue(itemId, field, e.target.value);

      // an item's value is being updated after ranked
      updateRankItemValue(itemId, field, e.target.value);
    };
  }

  const updateItemValue = (itemId, field, value) => {
    let newItems = [...items];
    newItems[itemId] = {
      ...newItems[itemId],
      [field]: value,
    };
    setItems(newItems);
  };

  const updateRankItemValue = (itemId, field, value) => {
    const rankItemIndex = findRankItemIndex(itemId);
    if (rankItemIndex === -1) return;

    const newRanking = [...ranking];
    newRanking.splice(rankItemIndex, 1, {
      ...ranking[rankItemIndex],
      [field]: value,
    });
    setRanking(newRanking);
  }

  const onConfirmClick = async ({ uploadFormData, comment, fbUserID, fbEmail }) => {
    // if (!fbUserID) return setError(NEED_FB_LOGGED_IN);
    setIsLoadingConfirm(true);
    setShowModal(false);
    let images;
    try {
      if (uploadFormData) {
        const uploadResponse = await axios.post('/api/uploads', uploadFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        images = uploadResponse.data.names;
      }
    } catch (error) {
      console.error(`upload error: `, error);
    }
    if (ranking || comment) {
      const token = await executeRecaptcha('action');
      const { name, comment: parseComment } = commentName(comment);
      const reviewPayload = {
        ranking,
        fbUserID,
        fbEmail,
        recaptchaToken: token,
        feedbackSubmit: parseComment ? true : false,
        photoSubmit: uploadFormData ? true : false,
        emojiSubmit: false,
        submittedAt: dayjs().tz(TIMEZONE).format(FORMAT),
      };
      const feedbacksPayload = {
        name,
        comment: parseComment,
        images: images?.join(','),
      }
      try {
        await axios.all([
          parseComment ? axios.post('/api/feedbacks', feedbacksPayload) : null,
          ranking ? axios.post(`/api/reviews`, reviewPayload) : null,
        ])
        if (window.fbq !== null) {
          window.fbq('track', 'Review Submitted');
        }
        setIsLoadingConfirm(false);
        navigate('/thankyou', { replace: true });
      } catch (err) {
        const { status } = err.response;

        // TODO: uncomment this for FB login to post
        if (status === 400) {
          setIsLoadingConfirm(false);
          return setError(NEED_FB_LOGGED_IN);
        }

        if (status === 403) {
          setIsLoadingConfirm(false);
          return setError('Your response has already been submitted');
        }

        setIsLoadingConfirm(false);
        navigate('/thankyou', { replace: true });
      };
    }
  };

  return (
    <div className="container review">
      <ScrollToTop />
      <div className="row justify-content-around">
          <h3 className="mb-4 text-left" dangerouslySetInnerHTML={{ __html: data.question }}></h3>
          {
            !isLoading &&
            items.map((v, i) => (
              <div className={classNames('item', { 'open': v.open })} key={i}>
                <div className={
                  classNames('rank', { 'selected': getRankOf(i) !== undefined })}
                  onClick={onItemClick(i)}>
                  {
                    (isNaN(getRankOf(i) + 1)) ? '' : (getRankOf(i) + 1)
                  }
                </div>
                <div className="info">
                  {
                    v.open ?
                      <div className="d-flex w-100">
                        <input className="model" type="text"
                          placeholder="Enter your shoe model"
                          onClick={() => {
                            const found = ranking.find(item => item.itemId === i);
                            if (found) return;
                            onItemClick(i)();
                          }}
                          onChange={onInputChange(i, 'model')}
                          name={`model-${i + 1}`}
                          autoComplete="off" />
                      </div>
                      :
                      <div className="brand-model" onClick={onItemClick(i)}>
                        {v.brand} {v.model}
                      </div>
                  }
                </div>
              </div>
            ))
          }
        </div>

        <div className="next">
          <div className="next-btn-wrap">
            <div className="error">{error}</div>
            <button id="submit-btn" className="next-btn border-0" onClick={onNextClick}>
              <NextLogo />
            </button>
          </div>
        </div>

        <ConfirmModal show={showModal}
          onConfirm={onConfirmClick}
          onClose={(e) => { setShowModal(false) }}
        />
        <Loading show={isLoadingConfirm} />

    </div>
  );
}

export default Review;