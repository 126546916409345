import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DataProvider } from './context/ContextProvider';

const reCaptchaKey = '6LceBZ4pAAAAAP6lsq5KG98BlUV3khWJ8mAiJe6T';

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
    <React.StrictMode>
      <DataProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </DataProvider>
    </React.StrictMode>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
