import { useContext } from "react"
import { useEffect, useState, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { FacebookShareButton, FacebookIcon } from "react-share";
import axios from 'axios';
import shoeImg from '../assets/image/shoe.png';
import Votes from './Votes';
import { DataContext } from '../context/ContextProvider';


import rank1Medal from '../assets/emoji/MaskGroup101@2x.png';
import rank2Medal from '../assets/emoji/MaskGroup104@2x.png';
import rank3Medal from '../assets/emoji/MaskGroup108@2x.png';
import CloseIcon from '../assets/icon/close.svg';

import './Ranking.scss';

const TableRowItem = ({ data }) => {
  const navigate = useNavigate();

  const shoeOnClick = (item) => {
    if (!item.product_id) return;
    const { brand, model, product_id } = item;
    navigate(`/shoes/${product_id}/${brand} ${model}`);
  };

  const Rank = ({ rank }) => {
    if (typeof rank !== 'number') return false;

    if (rank > 3) return (
      <p className="h2 font-weight-bold my-0">{data.rank}</p>
    );

    const imgSrc = [rank1Medal, rank2Medal, rank3Medal][rank - 1];

    return (
      <div className="rank-icon">
        <img className="rank-icon rank-icon-image" src={imgSrc} alt={rank} />
        <p className="h2 font-weight-bold rank-icon rank-icon-text">
          {data.rank}
        </p>
      </div>
    );
  }

  return (
    <tr style={{ cursor: "pointer" }} className="table-rows" onClick={(e) => shoeOnClick(data)}>
      <th scope="row">
        <div className="d-flex justify-content-center align-items-center">
          <Rank rank={data.rank} />
        </div>
      </th>
      <td className="custom-width">
        <div className="d-flex justify-contentmd-start align-items-center">
          <img src={data.image_url || shoeImg} className="card-img-top"
              alt={data.model} />
          <p className="h6 text-wrap fw-bold my-0 name-title">
            {data.brand}<br />{data.model}
          </p>
        </div>
      </td>
      <td className="text-center"><p className="h2 my-0"><small>$</small>{data.price}</p></td>
      <td className="h2 text-center">{data.percentile ? data.percentile + '%' : '-'}</td>
    </tr>
  );
};

const ContentSection = memo(function ContentSection({ data = [] }) {
  return (
    <>
      <div className="p-0">
        <table className="table table-borderless">
          <thead> 
            <tr className="table-rows">
              <th className="text-center table-title" scope="col">Rank</th>
              <th className="text-center table-title" scope="col">Name</th>
              <th className="text-center table-title" scope="col">Price</th>
              <th className="text-center table-title" scope="col">Percentile</th>
            </tr>
          </thead>
          <tbody>
            { data.map((v, i) => <TableRowItem key={i} order={i} data={v} />) }
          </tbody>
        </table>
      </div>
    </>
  )
})

function Ranking() {
  const [ranking, setRanking] = useState([]);
  const [reviewCount, setReviewCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useContext(DataContext);

  useEffect(() => {
    setTimeout(() => {
      const greeting = document.querySelector('.greeting');
      greeting.classList.remove('hide');
      void greeting.offsetWidth;
      greeting.classList.add('show'); 
  }, 10000);


    setIsLoading(true);
    let y = localStorage.getItem(`scroll-y`) || 0;
    setTimeout(() => {
      window.scrollTo(0, y);
    }, 150);
    window.addEventListener("scroll", trackScrolling);

    axios.get('/api/ranking')
      .then(res => {
        const { ranking, reviewCount } = res.data;
        const rank = ranking.filter(item => item.rank);
        const noRank = ranking.filter(item => item.rank === null)
          .sort((first, second) => (
            parseInt(second.total_votes) - parseInt(first.total_votes)
          ));
        setReviewCount(reviewCount)
        setRanking([
          ...rank,
          ...noRank,
        ]);
      })
      .catch(err => { })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      window.removeEventListener("scroll", trackScrolling);
    }
  }, []);

  const trackScrolling = (e) => {
    localStorage.setItem(`scroll-y`, window.scrollY);
  }

  const onClickHideGreeting = () => {
    const greeting = document.querySelector('.greeting');
    greeting.classList.remove('show');
    greeting.classList.add('hide'); 
  }

  return (
    <div className="container ranking">

      <div className="d-flex justify-content-end align-items-center w-100">
        <div className="d-flex justify-content-end align-items-center w-50">
          <div className="greeting hide">
            <button className="hide-greeting-button" onClick={onClickHideGreeting}>
              <img width={16} height={16} src={CloseIcon} alt={'CloseIcon'} />
            </button><br/>
            Hey there, fellow road warriors!<br/>Now that you've strapped on<br/>those carbon-plated wonders,<br/>we want to hear from you —<br/>inspire other runners</div>
          <Link className="rank-button" to="/review">
            Rank 1-2-3
          </Link>
        </div>
      </div>
      
      <div className="mb-3">
        <div className="row justify-content-around">
            <div className="d-flex justify-content-between align-items-md-center mb-2 flex-column flex-md-row align-items-start">
              {/* <h1 className="mt-2">Best Carbon Plate <br /> Running Shoes 2024</h1> */}
              <h1 className="mt-2" dangerouslySetInnerHTML={{ __html: data.title }}></h1>

              {/* <FacebookShareButton
                style={{ backgroundColor: '#4267B2', color: '#fff', padding: '3px', borderRadius: '2px' }}
                url={window.location.href}
                quote={document.title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={24} borderRadius={8} bgStyle={{ fill: '#fff' }} iconFillColor="#4267B2" /> Share
              </FacebookShareButton> */}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="my-0 text-secondary ranking ranking-subtitle">
                {reviewCount || 0} reviews
              </p>
              <p className="my-0 text-secondary small">
                { data.updateDate }
              </p>
              
            </div>
          { !isLoading ? <ContentSection data={ranking} />  : false }
          <Votes />
        </div>
      </div>
      <div className="pt-5"></div>
    </div>
  );
}

export default Ranking;
